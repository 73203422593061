import { useStaticQuery, graphql } from "gatsby";
import React, { ReactElement, FC } from "react";

export const SplashContainer: FC<{ className?: string }> = ({
    children,
    className,
}): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutLoginForm {
                edges {
                    node {
                        backgroundImage {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutLoginForm.edges[0].node;

    const backgroundImage = `url(${content.backgroundImage.file.url})`;

    return (
        <div
            style={{ backgroundImage, backgroundSize: "cover" }}
            className={className}
        >
            {children}
        </div>
    );
};
